import { memo, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { MdSearch } from 'react-icons/md'
import Spinner from '../../../components/assets/Spinner'

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly defaultValue?: string
  readonly category?: string
  readonly register: UseFormRegister<any>
}

function SearchTerm(props: PropsType) {
  const { id = 'searchbar', category = 'Term', register, defaultValue } = props
  const [isBusy, setIsBusy] = useState(false)

  const handleSubmit = () => {
    setIsBusy(true)
    setTimeout(() => {
      setIsBusy(false)
    }, 2000)
  }

  const inputId = id + '-input'
  return (
    <label
      className="mood-white flex h-11 w-full items-center gap-2 rounded-md border border-grey-light"
      htmlFor={inputId}
    >
      <span className="sr-only">Search Terms</span>
      <input
        id={inputId}
        autoCapitalize="off"
        autoCorrect="off"
        className="peer/searchinput w-full truncate rounded-md border-0 p-0 transition-colors placeholder:italic focus:ring-0"
        placeholder="Search..."
        spellCheck={false}
        type="text"
        defaultValue={defaultValue}
        {...register('term')}
      />
      <span
        className="order-first border-r border-grey-light px-2 font-700 text-orange peer-focus/searchinput:border-current"
        role="presentation"
      >
        {category}
      </span>
      <button
        onClick={handleSubmit}
        type="submit"
        className="flex h-full min-w-11 items-center justify-center rounded-r-md bg-orange text-white"
        title="submit"
      >
        <span className="sr-only">Search {category} </span>
        {isBusy ? (
          <Spinner width={20} height={20} />
        ) : (
          <MdSearch size="1.75em" />
        )}
      </button>
    </label>
  )
}

/**
 * Captures `terms` for the search query.
 *
 * @example
 * const { register } = useRouterControl()
 * <SearchTerm category="Jobs" register={register} />
 */
export default memo(SearchTerm)
