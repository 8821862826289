import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import {
  CompanyPhotoDisplayRoleEnum,
  CompanyPhotoMediaTypeEnum,
  PagePhotoInput,
} from '@vangst/services/oogst/types'
import React, { useState } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import {
  MdDelete,
  MdErrorOutline,
  MdPhotoCamera,
  MdVideocam,
} from 'react-icons/md'
import ReactPlayer from 'react-player'
import AlertText from '../../../components/feedback/AlertText'
import Attachment from '../../../components/forms/Attachment'
import HiddenInput from '../../../components/forms/HiddenInput'
import useAttachmentz from '../../../components/forms/useAttachmentz'
import ClickyLink from '../../../components/navigation/ClickyLink'
import DialogVideoUrl from '../feedback/DialogVideoUrl'

type PropsType = {
  // readonly defaultValue?: CompanyPhotoInput
  // TODO: Meh... this is from the Maybe/null change :shrug:
  readonly defaultValue?:
    | {
        readonly alt?: string | null
        readonly displayRole?: CompanyPhotoDisplayRoleEnum | null
        readonly id?: string
        readonly mediaType?: CompanyPhotoMediaTypeEnum | null
        readonly url: string
      }
    | PagePhotoInput
  readonly name: string
  readonly as?: 'hero' | 'companyPhoto' | 'avatar'
  readonly register: UseFormRegister<any>
  readonly setValue: UseFormSetValue<any>
}

function AttachmentPhoto(props: PropsType) {
  const [invalidType, setInvalidType] = useState(false)
  const { as, defaultValue, name, register, setValue } = props

  const { attachment, handleSelected, remove } = useAttachmentz({
    attachment: defaultValue,
    reportInvalidType: (state: boolean) => setInvalidType(state),
    requiredType: 'image',
  })

  const [showDialog, setShowDialog] = useState(false)
  const [videoUrl, setVideoUrl] = useState(
    defaultValue?.mediaType === CompanyPhotoMediaTypeEnum.Video
      ? defaultValue.url
      : '',
  )
  const handleClick = () => {
    setShowDialog(true)
  }

  const { ref: photoUrlRef } = register(`${name}.url`)
  const { ref: photoAltRef } = register(`${name}.alt`)
  const { ref: photoIdRef } = register(`${name}.id`)
  const { ref: photoDisplayRoleRef } = register(`${name}.displayRole`)
  const { ref: photoMediaTypeRef } = register(`${name}.mediaType`)

  const setVideoData = (url: string) => {
    setShowDialog(false)
    setValue(`${name}.url`, url)
    setValue(`${name}.mediaType`, CompanyPhotoMediaTypeEnum.Video)
    setValue(`${name}.displayRole`, CompanyPhotoDisplayRoleEnum.Standard)
    setVideoUrl(url)
  }

  const { id, blob, error, url } = attachment || {}

  // UPLOADING A PHOTO
  if (blob != null && error == null && url == null) {
    const className = as === 'hero' ? 'h-64' : 'w-76 h-56'
    return (
      <div
        className={clsx(
          className,
          as === 'hero' && 'h-64',
          'mood-grey-dark flex flex-col items-center justify-center rounded-lg',
        )}
      >
        <Attachment theme="pending">Uploading {blob.name}</Attachment>
      </div>
    )
  }
  // EMPTY STATES
  if (isEmptyOrNil(attachment) && !videoUrl) {
    // HERO
    return as === 'hero' ? (
      <>
        <Attachment as={as}>
          <label
            aria-label="Add Photo"
            htmlFor={name}
            className="file-input flex w-full items-center justify-center"
          >
            <div className="text-grey-lightest">
              <p className="mb-4 flex flex-col items-center justify-center">
                <MdPhotoCamera />
              </p>
              <p className="text-sm uppercase">
                <span className="">add photo</span>
              </p>
            </div>

            <input
              id={name}
              onChange={handleSelected}
              type="file"
              accept="image/*"
            />
          </label>
        </Attachment>
        {invalidType && (
          <AlertText className="text-sm italic text-red" icon={MdErrorOutline}>
            File must be an image.
          </AlertText>
        )}
      </>
    ) : (
      // NON-HERO
      <div className="mood-grey-dark relative grid h-56 w-76 rounded-lg">
        <div className="grid grid-cols-2">
          <Attachment as={as}>
            <label aria-label="Add Photo" htmlFor={name} className="file-input">
              <div className="ml-2 flex w-36 flex-col items-center justify-center text-grey-lightest">
                <p>
                  <MdPhotoCamera />
                </p>
                <p className="mt-4 text-sm uppercase">
                  <span className="">add photo</span>
                </p>
              </div>

              <input
                id={name}
                onChange={handleSelected}
                type="file"
                accept="image/*"
              />
            </label>
          </Attachment>
          <ClickyLink
            onClick={handleClick}
            className="ml-2 w-36 text-grey-lightest"
          >
            <span className="flex flex-col items-center justify-center">
              <MdVideocam />
              <span className="mt-4 text-sm uppercase">add video</span>
            </span>
          </ClickyLink>
          {showDialog && (
            <DialogVideoUrl
              handleCancel={() => setShowDialog(false)}
              handleConfirm={(url: string) => setVideoData(url)}
            />
          )}
        </div>
        {invalidType && (
          <AlertText
            className="absolute bottom-4 left-4 text-red"
            icon={MdErrorOutline}
          >
            File must be an image.
          </AlertText>
        )}
      </div>
    )
  }

  const clearVideo = () => {
    setValue(`${name}.url`, '')
    setValue(`${name}.id`, '')
    setValue(`${name}.mediaType`, '')
    setValue(`${name}.displayRole`, '')
    setVideoUrl('')
    if (id) remove(id)
  }

  const clear = () => {
    setValue(`${name}.url`, '')
    setValue(`${name}.id`, '')
    setValue(`${name}.mediaType`, '')
    setValue(`${name}.displayRole`, '')
    if (id) remove(id)
  }

  // A new photo has been uploaded:
  const role =
    as === 'hero'
      ? CompanyPhotoDisplayRoleEnum.Hero
      : CompanyPhotoDisplayRoleEnum.Standard
  if (url != null && blob) {
    setValue(`${name}.url`, url)
    setValue(`${name}.mediaType`, CompanyPhotoMediaTypeEnum.Photo)
    setValue(`${name}.displayRole`, role)
  }
  return (
    // A COMPANY GALLERY OR HERO PHOTO
    <>
      {!videoUrl && (
        <div
          className={clsx(
            as !== 'hero' && 'h-56 w-76',
            'mood-grey-dark rounded-lg',
          )}
        >
          <Attachment as={as} preview remove={clear} url={url}>
            <HiddenInput
              defaultValue={url || undefined}
              name={`${name}.url`}
              reference={photoUrlRef}
            />
            <HiddenInput
              defaultValue=""
              name={`${name}.alt`}
              reference={photoAltRef}
            />
            <HiddenInput
              defaultValue=""
              name={`${name}.displayRole`}
              reference={photoDisplayRoleRef}
            />
            <HiddenInput
              defaultValue=""
              name={`${name}.mediaType`}
              reference={photoMediaTypeRef}
            />
            <HiddenInput
              defaultValue={blob == null ? id || undefined : ''}
              name={`${name}.id`}
              reference={photoIdRef}
            />
          </Attachment>
        </div>
      )}

      {videoUrl && (
        // A COMPANY GALLERY VIDEO
        <div className="mood-grey-dark relative flex h-56 w-76 items-center rounded-lg">
          <a
            className="absolute size-full"
            href={videoUrl}
            target="_blank"
            rel="noreferrer"
          >
            <ReactPlayer
              light={true}
              style={{ borderRadius: 4, overflow: 'hidden', opacity: '60%' }}
              width="100%"
              height="100%"
              url={videoUrl || url || undefined}
            />
            <span className="sr-only">Watch {videoUrl}</span>
          </a>
          <ClickyLink
            as="icon"
            icon={MdDelete}
            className="absolute right-4 top-4"
            onClick={clearVideo}
          >
            Remove
          </ClickyLink>
        </div>
      )}
      {error != null && (
        <Attachment as={as} remove={clear} theme="failure">
          An error occurred uploading {blob?.name}
        </Attachment>
      )}
    </>
  )
}

export default React.memo(AttachmentPhoto)
