// REMINDER TO UPDATE THE SITEMAP WHENEVER YOU ADD/CHANGE A ROUTE
// eslint-disable-file @typescript-eslint/no-duplicate-enum-values
enum Routes {
  // Internal
  INDEX = '/jobs',

  ABOUT = '/about',
  ABOUT_CONTACT = '/about/contact',
  ABOUT_TESTIMONIALS = '/about/testimonials',

  ADMIN_IMPOSTOR = '/admin/impostor',

  BLOG = '/blog',
  BLOG_VANGST = 'https://vangst.com/blog',
  BLOG_DETAIL = '/blog/:slug',

  COMPANIES = '/companies',
  COMPANIES_DETAIL = '/companies/:displayname',
  COMPANIES_EDIT = '/companies/:displayname/edit',
  COMPANIES_FOLLOWERS = '/companies/:displayname/followers',
  COMPANIES_JOBS = '/companies/:displayname/jobs',
  COMPANIES_JOBS_APPLICATIONS = '/companies/:displayname/jobs/:id/applications',
  COMPANIES_JOBS_APPLICATIONS_DETAIL = '/companies/:displayname/jobs/:jid/applications/:aid',
  COMPANIES_JOBS_LEADS_DETAIL = '/companies/:displayname/jobs/:jid/leads/:lid',
  COMPANIES_JOBS_EDIT = '/companies/:displayname/jobs/:id/edit',
  COMPANIES_JOBS_GIGS = '/companies/:displayname/jobs/gigs',
  COMPANIES_JOBS_GIGS_PLACEMENTS = '/companies/:displayname/jobs/gigs/placements',
  COMPANIES_JOBS_LEADS = '/companies/:displayname/jobs/:id/leads',
  COMPANIES_JOBS_NEW = '/companies/:displayname/jobs/new',
  COMPANIES_JOBS_PLACEMENTS = '/companies/:displayname/jobs/:id/placements',
  COMPANIES_JOBS_PLACEMENTS_MANAGE = '/companies/:displayname/jobs/:id/placements/manage',
  COMPANIES_TRAINING_MEMBERS = '/companies/:displayname/trainings/:tid/members',
  COMPANIES_TRAINING = '/companies/:displayname/trainings/:id',
  COMPANIES_TRAININGS = '/companies/:displayname/trainings',
  COMPANIES_COURSE = '/companies/:displayname/trainings/:tid/courses/:id',

  DASHBOARD = '/dashboard',

  DIRECT_HIRE = '/direct-hire',

  EVENTS_CAREER_FAIRS = '/events/career-fairs',
  EVENTS_DETAIL = '/events/:slug',

  FAQ = 'https://www.vangst.com/faqs-employee',

  GIGS = '/gigs',

  HELP = '/help',

  JOBS = '/jobs',
  JOBS_BROWSE = '/jobs/browse',
  JOBS_DETAIL = '/jobs/:id',

  LEARN = '/learn',

  LEGAL = '/legal',
  LEGAL_FAQ_DH_PLACEMENTS = '/legal/direct-hire-placements-faq',
  LEGAL_FAQ_GIGS_SERVICES = '/legal/gigs-services-faq',
  LEGAL_PRIVACY = '/legal/privacy',
  LEGAL_STANDARD_TERMS = '/legal/standard-terms-and-conditions-for-services',
  LEGAL_TERMS = '/legal/terms',

  MEMBER_CLAIM_ACCOUNT = '/member/claim-account',
  MEMBER_FORGOT_PASSWORD = '/member/forgot-password',
  MEMBER_RESET_PASSWORD = '/member/reset-password',

  MEMBERS = '/members',
  MEMBERS_APPLICATIONS = '/members/:username/applications',
  MEMBERS_CONNECTIONS = '/members/:username/connections',
  MEMBERS_DETAIL = '/members/:username',
  MEMBERS_EDIT = '/members/:username/edit',
  MEMBERS_FEATURED = '/members/featured',
  MEMBERS_MESSAGES = '/members/:username/messages',

  ORANJE = '/oranje',
  ORANJE_STORIES = '/oranje/stories',
  ORANJE_LAYOUTS = '/oranje/layouts',

  REPORTS = '/reports',
  REPORTS_JOBS_2023 = '/reports/2023-jobs-report',
  REPORTS_JOBS_2024_VANGST = 'https://www.vangst.com/2024-jobs-report',
  REPORTS_SALARY_GUIDE = '/reports/salary-guide',
  REPORTS_SALARY_GUIDE_SURVEY = '/reports/salary-guide-survey',
  REPORTS_STATE_REQUIREMENTS = '/reports/state-requirements',
  REPORTS_STATE_REQUIREMENTS_DETAIL = '/reports/state-requirements/:slug',
  REPORTS_DETAIL = '/reports/:slug',

  PLATFORM = '/platform',

  RESOURCES = '/resources',

  SEARCH = '/search',

  // Auth
  SIGN_IN = '/sign-in',
  SIGN_OUT = '/sign-out',
  SIGN_UP = '/sign-up',
  SIGN_UP_COMPANY = '/company/sign-up',
  INVITE_USER = '/invite-user',

  // Sitemap
  SITEMAP_COMPANIES = 'https://clients-sitemap.vangst.com/current/clients-sitemap_1.xml',
  SITEMAP_JOBS = 'https://jobs-sitemap.vangst.com/current/jobs-sitemap_1.xml',
  SITEMAP_PAGES = 'https://pages-sitemap.vangst.com/current/pages-sitemap_1.xml',

  // Training
  TRAININGS = '/trainings',

  // Vangst Apps
  FRESH_TEAM = 'https://vangst.freshteam.com/jobs',
  // SALARY_GUIDE = 'https://info.vangst.com/vangst-2019-salary-guide',
  SALARY_GUIDE_SURVEY_LINK = 'https://vangst.typeform.com/sg-2021-survey',
  KATE_DEMO = 'https://meetings.hubspot.com/kate-caffrey',
  SCHEDULE_DEMO = 'https://meetings.hubspot.com/vangstdemo/tour-the-vangst-network',
  SCHEDULE_CONSULT = 'https://meetings.hubspot.com/vangstdemo/consult-with-vangst',

  // Social
  FACEBOOK = 'https://www.facebook.com/Vangsttalent',
  INSTAGRAM = 'https://www.instagram.com/vangsttalent',
  LINKED_IN = 'https://www.linkedin.com/company/vangst',
  TWITTER = 'https://twitter.com/vangsttalent',

  // EMAILS
  MAILTO_SUPPORT = 'mailto:support@vangst.com',

  // ASSETS
  DEFAULT_VANGST_LOGO = 'https://assets.vangst.com/logos/vangst/VangstLogo_Stacked_BlkBG_RGB.jpeg',
  GENERIC_USER_AVATAR = 'https://assets.vangst.com/community/emptylogo.png',
  GENERIC_CREDENTIAL = 'https://assets.vangst.com/credentials/default-credential.png',
}

export default Routes
