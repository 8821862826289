import { formatReadableDateWords } from '@vangst/lib/formatDates'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import titleize from '@vangst/lib/titleize'
import {
  MemberComputed,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ActivitiesRenderer from '../../application/ActivitiesRenderer'
import {
  emptyText,
  TableBodyDetailList,
  TableRowBody,
} from '../../application/TableRow'
import { educationLevelFormatting } from './Education'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly member?: MemberComputed
  readonly variables: MemberVars
}

function MemberTableRowBody(props: PropsType) {
  const { member } = props
  const { viewer } = useViewer()

  const education = member?.candidate?.education
  const employment = member?.candidate?.experiences
  const summary = member?.candidate?.summary

  return (
    <TableRowBody>
      <div className="grid grid-cols-3">
        <TableBodyDetailList title="Licenses">
          <span className="flow-y-xs content-start">
            {!member?.candidate?.candidateLicenses ||
            member?.candidate?.candidateLicenses?.length === 0
              ? emptyText
              : member?.candidate?.candidateLicenses?.map((item) =>
                  item?.url ? (
                    <ClickyLink
                      key={`${item.id}-${member?.id}`}
                      className="link-orange text-sm"
                    >
                      {item?.license?.name}
                    </ClickyLink>
                  ) : (
                    <span
                      key={`${item.id}-${member?.id}`}
                      className="flow-y-xs text-sm"
                    >
                      {`${item.license?.name}`}
                    </span>
                  ),
                )}
          </span>
        </TableBodyDetailList>
        <TableBodyDetailList title="Applications">
          <span className="flow-y-xs content-start">
            {!member?.candidate?.jobApplications ||
            member?.candidate?.jobApplications?.length === 0
              ? emptyText
              : member?.candidate?.jobApplications?.map((item) => (
                  <ClickyLink
                    key={`${item.jobPosting.id}-${member?.id}`}
                    href={Routes.COMPANIES_JOBS_APPLICATIONS.replace(
                      ':displayname',
                      item?.jobPosting?.client?.displayname ?? '',
                    ).replace(':id', item.jobPosting.slug)}
                    className="link-orange text-sm"
                  >
                    {titleize(item?.status?.split('_').join(' ') ?? '')}:{' '}
                    {item?.jobPosting?.title}
                  </ClickyLink>
                ))}
          </span>
          {member?.candidate?.jobApplications &&
            member?.candidate?.jobApplications?.length > 0 && (
              <ClickyLink
                as="text-icon"
                href={member?.routes?.jobApplications}
                className="link-orange mt-4 text-sm underline"
              >
                View All
              </ClickyLink>
            )}
        </TableBodyDetailList>
        <TableBodyDetailList title="Placements">
          <span className="flow-y-xs content-start">
            {!member?.candidate?.placements
              ? emptyText
              : member?.candidate?.placements?.map((item) => (
                  <ClickyLink
                    key={`${item.jobPosting.id}-${member?.id}`}
                    href={Routes.COMPANIES_JOBS_PLACEMENTS.replace(
                      ':displayname',
                      item?.jobPosting?.client?.displayname ?? '',
                    ).replace(':id', item.jobPosting.slug)}
                    className="link-orange text-sm"
                  >
                    {titleize(item?.status?.split('_').join(' ') ?? '')}:{' '}
                    {item?.jobPosting?.title}
                  </ClickyLink>
                ))}
          </span>
        </TableBodyDetailList>
      </div>
      <hr />
      <div>
        <TableBodyDetailList title="About Me">
          {summary == null
            ? emptyText
            : summary?.entry.split(/\n/).map((line) => (
                <span
                  className="prose block text-sm"
                  key={`${line}-${member?.id}`}
                >
                  {line}&nbsp;
                </span>
              ))}
        </TableBodyDetailList>
      </div>

      <hr />
      <div className="grid grid-cols-2 pt-4">
        <TableBodyDetailList title="Education">
          <ul className="flow-y-sm">
            {isEmptyOrNil(education)
              ? emptyText
              : education?.map((education) => (
                  <li key={education.id} className="flex flex-col">
                    <span className="text-sm font-600">
                      {education?.school}
                    </span>
                    {education.educationLevel && (
                      <span>
                        {educationLevelFormatting[education.educationLevel]} -{' '}
                        {education?.degree}
                      </span>
                    )}
                    <span>{education?.yearsAttended} Years Attended</span>
                  </li>
                ))}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList title="Employment">
          <ul className="flow-y-sm">
            {isEmptyOrNil(employment)
              ? emptyText
              : employment?.map((experience) => {
                  const start =
                    experience?.startDate != null
                      ? `${formatReadableDateWords(
                          new Date(experience?.startDate),
                        )}`
                      : null
                  const end =
                    experience?.endDate != null
                      ? `- ${formatReadableDateWords(
                          new Date(experience?.endDate),
                        )}`
                      : '- Unknown End Date'
                  const date = `${start} ${
                    !experience.current ? end : '- Present'
                  }`
                  return (
                    <li key={experience.id} className="flex flex-col">
                      <span className="text-sm font-600">
                        {experience?.companyName}
                      </span>
                      <span>{experience?.jobTitle}</span>
                      {start != null && <span>{date}</span>}
                      <span>{experience?.responsibilities}</span>
                    </li>
                  )
                })}
          </ul>
        </TableBodyDetailList>
      </div>
      {viewer?.permissions.internalUser && (
        <>
          <hr />
          <div className="grid">
            <TableBodyDetailList title="Activity">
              <span className="block max-h-80 overflow-y-auto">
                <ActivitiesRenderer
                  variables={{ username: member?.username ?? '', first: 10 }}
                  endpoint="getMemberActivities"
                  relayId={member?.id}
                />
              </span>
            </TableBodyDetailList>
          </div>
        </>
      )}
    </TableRowBody>
  )
}

export default memo(MemberTableRowBody)
